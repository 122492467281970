import * as React from "react";
import { PartenairePro } from "../util/types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Card from "./card";

export interface PartnerCardProps {
  partner: PartenairePro;
}

const PartnerCard: React.FC<PartnerCardProps> = ({
  partner: { logo, name },
}) => {
  return (
    <Card className={`h-[100px] p-2 xl:h-[150px]`} as={"div"}>
      {logo &&
        (logo.localFile?.childImageSharp ? (
          <GatsbyImage
            image={getImage(logo.localFile.childImageSharp)}
            alt={`logo de ${name}`}
            objectFit={"contain"}
            className="h-full w-full h-full"
          />
        ) : (
          <img
            src={logo.localFile?.publicURL}
            alt={`logo de ${name}`}
            className="object-contain h-full w-full h-full"
          />
        ))}
    </Card>
  );
};

export default PartnerCard;
