import * as React from 'react';
import { Layout } from '../components/layout';
import Seo from '../components/seo';
import { graphql } from 'gatsby';
import { PartenairePro, ThemePRO, ThemeSection } from '../util/types';
import Title from '../components/title';
import { useEffect, useState } from 'react';
import ThemeSidebar from '../components/theme-pro-sidebar';
import slugify from '@sindresorhus/slugify';
import ContactForm from '../components/contact-form';
import Modal from '../components/modal';
import Calculator from '../components/calculator';
import PartnerCard from '../components/partnercard';
import Carousel from '../components/carousel';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface ThemePageProps {
    data: {
        theme: ThemePRO;
        partners: {
            all: Array<PartenairePro>;
        };
    };
}

const ThemePage: React.FC<ThemePageProps> = ({
    data: {
        theme: {
            SEO: { slug, title, description },
            Home,
            Services
        },
        partners: { all: partners }
    }
}) => {
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const [currentSubjectIndex, setCurrentSubjectIndex] = useState(0);

    const sections: ThemeSection[] = Services.reduce((acc, currValue) => {
        return [...acc, ...currValue.sections];
    }, [] as ThemeSection[]);

    let selectedSubject = slugify(sections[currentSubjectIndex]?.title);

    /**
     * Check the scroll to change the current selected section on the sidebar
     */
    const checkScroll = () => {
        setCurrentSubjectIndex((prevIndex) => {
            if (sections.length > 1) {
                // when scrolling down
                let nextCategorySlug =
                    sections[prevIndex + 1] &&
                    slugify(sections[prevIndex + 1]?.title);
                let sectionNextCategory =
                    document.getElementById(nextCategorySlug);
                if (sectionNextCategory) {
                    let topOfSection = sectionNextCategory.offsetTop;
                    if (window.scrollY >= topOfSection - 150) {
                        return prevIndex + 1 >= sections.length
                            ? sections.length - 1
                            : prevIndex + 1;
                    }
                }

                // when scrolling up
                let prevCategorySlug =
                    sections[prevIndex - 1] &&
                    slugify(sections[prevIndex - 1]?.title);
                let sectionPrevCategory =
                    document.getElementById(prevCategorySlug);
                if (sectionPrevCategory) {
                    let bottomOfSection =
                        sectionPrevCategory.offsetTop +
                        sectionPrevCategory.offsetHeight;
                    if (window.scrollY <= bottomOfSection - 150) {
                        return prevIndex - 1 <= 0 ? 0 : prevIndex - 1;
                    }
                }
            }
            return prevIndex;
        });
    };

    useEffect(() => {
        checkScroll();

        window.addEventListener('scroll', checkScroll);
        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, []);

    return (
        <>
            <Layout>
                <Seo title={title} description={description} path={slug} />
                <main className="mt-20 mb-10">
                    <div className={'grid grid-cols-10 xl:grid-cols-12'}>
                        {/* SideBar */}
                        <div
                            className={`col-span-2 h-full bg-light-50 pb-52 ${
                                sideBarOpen
                                    ? 'col-span-10 pt-15 md:pt-20'
                                    : 'pt-10'
                            } md:pb-44 lg:col-span-3 xl:col-span-3
        `}>
                            <ThemeSidebar
                                open={sideBarOpen}
                                className={`w-full ${
                                    !sideBarOpen && 'sticky top-40'
                                } lg:sticky md:top-40`}
                                activeThemeSlug={slug}
                                selectedSectionSlug={selectedSubject}
                                onClose={() => setSideBarOpen(false)}
                                onThemeClicked={(_) => {
                                    setSideBarOpen(true);
                                    if (document) {
                                        scroll({
                                            top: document.querySelector('html')!
                                                .offsetTop,
                                            behavior: 'smooth'
                                        });
                                    }
                                }}
                            />
                        </div>

                        {/* Content */}
                        <div
                            className={`${
                                sideBarOpen && 'hidden'
                            } col-span-8 mb-10 space-y-24 pb-30 
              lg:block
              lg:pb-20 
              lg:col-span-7
              xl:col-span-9
              `}>
                            <section className={'!mt-20 px-15'}>
                                <Title className={'text-base'}>
                                    {Home?.title}
                                </Title>
                                <p className={'my-4 text-sm'}>
                                    {Home?.description}
                                </p>
                            </section>

                            {/* Sections */}
                            {Services.map(({ sections, title }, index) => {
                                const slug = slugify(title);

                                return (
                                    <section
                                        id={slug}
                                        key={slug}
                                        className={`px-15 space-y-20 ${
                                            index === 0 && '!mt-10'
                                        }`}>
                                        <h2
                                            className={
                                                'font-bold text-secondary-100'
                                            }>
                                            {title}
                                        </h2>
                                        {sections.map((section, i) => (
                                            <ThemePROSection
                                                className={
                                                    i === 0 ? '!mt-8' : ''
                                                }
                                                key={i}
                                                section={section}
                                                type={SectionTypes[i % 3]}
                                            />
                                        ))}
                                    </section>
                                );
                            })}

                            {/* Partners */}
                            <section
                                className={
                                    'px-15 my-8 overflow-x-hidden space-y-8 h-[200px] w-full xl:h-[300px]'
                                }>
                                <h2 className={'font-bold text-secondary-100'}>
                                    Ils nous font confiance :
                                </h2>
                                <div
                                    className={
                                        'flex space-x-4 justify-between'
                                    }>
                                    <Carousel
                                        numItemsOnLaptop={4}
                                        numItemsOnPC={4}
                                        numItemsOnTablet={3}
                                        numItemsOnPhone={1}>
                                        {partners.map((partner) => (
                                            <PartnerCard
                                                key={partner.id}
                                                partner={partner}
                                            />
                                        ))}
                                    </Carousel>
                                </div>
                            </section>
                        </div>
                    </div>
                </main>
            </Layout>
        </>
    );
};

const SectionTypes = ['first', 'second', 'third'] as const;

const ThemePROSection: React.FC<{
    section: ThemeSection;
    type: typeof SectionTypes[number];
    className?: string;
}> = ({
    section: {
        illustration,
        title,
        description,
        contactButtonLabel,
        demoButton
    },
    type,
    className = ''
}) => (
    <div
        className={`${className} grid gap-4 lg:grid-cols-2 lg:gap-8`}
        id={slugify(title)}>
        {/*Illustration*/}
        <div
            className={`h-[200px] md:h-[300px] ${
                type === 'second' && 'lg:order-last'
            }`}>
            {illustration && illustration.localFile?.childImageSharp ? (
                <GatsbyImage
                    image={getImage(illustration.localFile.childImageSharp)!}
                    alt={`illustration ${title}`}
                    objectFit={'contain'}
                    className={`object-contain w-full h-full drop-shadow-lg`}
                />
            ) : (
                <img
                    src={illustration.localFile?.publicURL}
                    alt={`illustration ${title}`}
                    className={`object-contain w-full h-full drop-shadow-lg`}
                />
            )}
        </div>

        {/*Text*/}
        <div className={'flex flex-col space-y-4'}>
            <h3 className={'font-bold text-sm mt-0'}>{title}</h3>
            <article
                className={'article__MEC text-sm'}
                dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className={'grid gap-4 xl:grid-cols-2'}>
                <ContactFormModal contactButtonLabel={contactButtonLabel} />
                {demoButton && (
                    <Calculator id={slugify(title)} demoButton={demoButton} />
                )}
            </div>
        </div>
    </div>
);

const ContactFormModal: React.FC<{ contactButtonLabel: string }> = ({
    contactButtonLabel
}) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            {/* Form */}
            <Modal open={open} onClose={() => setOpen(false)}>
                <ContactForm headerLabel={contactButtonLabel} />
            </Modal>

            {/* Toggle Button */}
            <button
                onClick={() => setOpen(true)}
                className={`px-2 py-3 text-sm w-full 
          rounded-lg 
          bg-primary-100
          text-white 
          md:text-base
          hover:shadow-primary`}>
                {contactButtonLabel}
            </button>
        </>
    );
};

export default ThemePage;

export const query = graphql`
    query ThemePRO($id: String) {
        theme: strapiThemesPro(id: { eq: $id }) {
            id
            SEO {
                slug
                title
                description
            }
            Services: Page {
                title
                sections: Section {
                    title
                    description
                    illustration {
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP]
                                )
                            }
                        }
                    }
                    contactButtonLabel
                    demoButton {
                        label
                        type
                    }
                }
            }
            Home {
                description
                title
            }
        }
        partners: allStrapiPartenairePro {
            all: nodes {
                id
                name
                logo {
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
            }
        }
    }
`;
