import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { ThemePRO, ThemeSection } from '../util/types';
import Icon from './icon';
import slugify from '@sindresorhus/slugify';

export interface ThemeSidebarProps {
    activeThemeSlug: string;
    selectedSectionSlug?: string;
    onClose: () => void;
    onThemeClicked: (id: string) => void;
    open?: boolean;
    className?: string;
}

interface ThemeList {
    themes: {
        all: Array<ThemePRO>;
    };
}

const ThemeSidebar: React.FC<ThemeSidebarProps> = ({
    activeThemeSlug,
    selectedSectionSlug,
    className = null,
    open = false,
    onClose,
    onThemeClicked
}) => {
    // query to get all the theme & subjects from "guide immo"
    const result: ThemeList = useStaticQuery(graphql`
        query ThemeSideBar {
            themes: allStrapiThemesPro {
                all: nodes {
                    id
                    SEO {
                        slug
                    }
                    Home {
                        title
                        illustration {
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(
                                        placeholder: BLURRED
                                        formats: [AUTO, WEBP]
                                    )
                                }
                            }
                        }
                    }
                    Services: Page {
                        id
                        title
                        sections: Section {
                            id
                            title
                            description
                            illustration {
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(
                                            placeholder: BLURRED
                                            formats: [AUTO, WEBP]
                                        )
                                    }
                                }
                            }
                            contactButtonLabel
                            demoButton {
                                label
                                type
                            }
                        }
                    }
                }
            }
        }
    `);

    // get themes
    const {
        themes: { all: themes }
    } = result;

    return (
        <aside className={`${className} bg-light-50`} style={{ zIndex: 7 }}>
            <nav>
                <ul className={'px-5'}>
                    <div
                        className={`flex justify-end ${
                            !open && 'hidden'
                        } lg:hidden`}>
                        {/*Close Button*/}
                        <button
                            onClick={onClose}
                            aria-label={'fermer le menu'}
                            className={
                                'self-end p-2 focus:ring-2 ring-blue-300 focus:outline-none'
                            }>
                            <Icon icon={'close'} className={'h-7'} />
                        </button>
                    </div>
                    {/* List of themes */}
                    {themes.map((theme) => (
                        <ThemeLine
                            theme={theme}
                            key={theme.id}
                            active={theme.SEO.slug === activeThemeSlug}
                            activeChild={selectedSectionSlug}
                            open={open}
                            onClose={onClose}
                            onSelect={() => {
                                onThemeClicked(theme.id);
                            }}
                        />
                    ))}
                </ul>
            </nav>
        </aside>
    );
};

const ThemeLine: React.FC<{
    theme: ThemePRO;
    active: boolean;
    activeChild?: string;
    open: boolean;
    onSelect: () => void;
    onClose: () => void;
}> = ({ theme, active, activeChild, open, onSelect, onClose }) => {
    /**
     * This function is used to implement smooth scrolling when the
     * user clicks on a subject in the same theme
     */
    const handleSubjectClick = (
        subjectSlug: string,
        parentThemeSlug: string
    ) => {
        const [_, currentThemeSlug] = window.location.pathname.split('/');
        if (currentThemeSlug !== parentThemeSlug || open) {
            navigate(`/${parentThemeSlug}#${subjectSlug}`);
        } else {
            // scroll to element
            let offsetTop =
                document.getElementById(subjectSlug)?.offsetTop ?? 0;
            scroll({
                top: offsetTop - 150,
                behavior: 'smooth'
            });
        }
        onClose();
    };

    return (
        <li
            className={`grid mt-8 ${
                open ? 'grid-cols-10' : 'grid-cols-2'
            } gap-4 lg:grid-cols-10`}>
            {/*Icon*/}
            <button
                title={theme.Home?.title}
                className={'col-span-2 flex justify-center'}
                onClick={onSelect}
                aria-label={`Sélectionner le thème ${theme.Home?.title}`}>
                <img
                    src={theme.Home?.illustration.localFile.publicURL}
                    alt={`Icône ${theme.Home?.title}`}
                    className={`object-contain filter h-15 ${
                        !active && 'opacity-25 grayscale'
                    }`}
                />
            </button>

            <div
                className={`col-span-8 self-start ${
                    !open && 'hidden'
                } lg:block`}>
                {/* Title */}
                <Link
                    to={`/${theme.SEO.slug}`}
                    className="font-bold uppercase text-sm">
                    {theme.Home?.title}
                </Link>

                {/* Services */}
                <ul>
                    {theme.Services.map(({ sections, title, id }) => {
                        const slug = slugify(title);
                        return (
                            <li key={`services-${id}`}>
                                <ul className={'mt-4'}>
                                    <li className={'text-secondary-100 mb-2'}>
                                        <a
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                                handleSubjectClick(
                                                    slug,
                                                    theme.SEO.slug
                                                );
                                            }}
                                            href={`/${theme.SEO.slug}#${slug}`}>
                                            {title}
                                        </a>
                                    </li>
                                    {sections.map((section) => {
                                        const slug = slugify(section.title);
                                        return (
                                            <li key={`section-${section.id}`}>
                                                <a
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        ev.stopPropagation();
                                                        handleSubjectClick(
                                                            slug,
                                                            theme.SEO.slug
                                                        );
                                                    }}
                                                    href={`/${theme.SEO.slug}#${slug}`}
                                                    className={`text-sm mt-0 grid grid-cols-10 hover:text-secondary-100 ${
                                                        activeChild !== slug
                                                            ? 'text-dark-50'
                                                            : 'text-dark-100'
                                                    }`}>
                                                    <span
                                                        className={`col-span-1 border-l-2 ${
                                                            activeChild === slug
                                                                ? 'border-secondary-100'
                                                                : 'border-gray-400/30'
                                                        }`}
                                                    />
                                                    <span
                                                        className={
                                                            'col-span-9'
                                                        }>
                                                        {section.title}
                                                    </span>
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </li>
    );
};

export default ThemeSidebar;
