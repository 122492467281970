import * as React from 'react';
import Helmet from 'react-helmet';
import { CalculatorType } from '../util/types';
import { clsx } from '@monemprunt/design-system';

export interface CalculatorProps {
    id: string;
    demoButton: { label: string; type: CalculatorType };
}

export function Calculator({
    id,
    demoButton: { label, type }
}: CalculatorProps) {
    const btnClassName = clsx(
        `px-2 py-3 rounded-lg !bg-secondary-100`,
        `text-white text-sm w-full`,
        `md:text-base hover:shadow-secondary`
    );
    const linkClassName = clsx(
        `text-secondary-100 underline h-full items-center`
    );

    React.useEffect(() => {
        // @ts-ignore
        if (typeof window.mec?.renderWidget === 'function') {
            // @ts-ignore
            window.mec.renderWidget();
        }
    }, []);

    // Pour transformer les types sur le site PRO en types pour strapi
    const typesForWidget: Record<CalculatorType, string> = {
        mensualites: 'mensualite',
        achat: 'emprunt',
        tunnel: 'tunnel'
    };

    return (
        <div id={id}>
            <Helmet>
                <link
                    rel="stylesheet"
                    href={`${process.env.GATSBY_WIDGET_URL}/style.css`}
                />

                <script
                    src={`${process.env.GATSBY_WIDGET_URL}/mec-widget.umd.js`}
                    async
                    defer
                />
            </Helmet>

            <>
                {/*Widget Tag*/}
                <div
                    className={clsx('mec--widget-app', {
                        'flex items-center h-full': type === 'mensualites'
                    })}
                    data-integration="wimodal"
                    data-label={
                        type === 'mensualites'
                            ? 'wimontant/mois sur wiyear ans'
                            : label
                    }
                    data-type-calcul={typesForWidget[type]}
                    data-wimodal-btn-class={
                        type === 'mensualites' ? linkClassName : btnClassName
                    }
                    data-montant-bien="310000"
                    data-mentions-legales-text="Mentions légales"
                    data-show-text-mentions-legales-on-button="true"
                    data-cle={process.env.GATSBY_WIDGET_CLE}
                    data-logo="https://www.monemprunt.com/static/logo-2f1d95c77461634bd37c3ff2b40fe368.svg"
                />
            </>
        </div>
    );
}

export default Calculator;
